import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import { StyledSideNav } from './index.styles'
import menuDocs from '../../pages/docs/menu.json'
import menuOver from '../../pages/over/menu.json'
import menuBlog from '../../pages/blog/menu.json'

function SideNav({ title }) {
  console.log(title)
  let menu;
  switch (title.substring(0, 5)) {
    case "/docs":
      menu = menuDocs
      break;
    case "/over":
      menu = menuOver
      break;
    case "/blog":
      menu = menuBlog
      break;
    default:
      break;
  }


  // title.substring(0, 5) === "/over" ? menuOver : menuDocs;
  // let menu = menuDocs;
  return (
    <StyledSideNav width={1 / 4}>
      {menu.map((category, i) => (
        <Fragment key={i}>
          <b>{category.title}</b>
          <ul>
            {category.children.map((child, j) => (
              <li key={j}><Link to={child.link} activeClassName="active">{child.title}</Link></li>
            ))}
          </ul>
        </Fragment>
      ))}

      <hr />
    </StyledSideNav>
  )
}

export default SideNav
