import styled from 'styled-components/macro'
import { mediaQueries } from '@commonground/design-system'

const Content = styled.div`
  width: 100%;
  ${mediaQueries.smUp`
    width: 75%;
  `}

  ${mediaQueries.smUp`
    padding-top: ${(p) => p.theme.tokens.spacing08};
  `}

  padding-bottom: ${(p) => p.theme.tokens.spacing08};
  overflow-wrap: break-word;

  code {
    font-size: 0.9em;
  }

  code[class*="language-"] {
    padding: 0.2em;
  }

  table {
    min-width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }

  table thead tr {
    background-color: #0b71a1;
    color: white;
    text-align: left;
}
  table th, table td {
    padding: 12px 15px;
  }
  table td:nth-child(1) {  
  /* your stuff here */
  font-weight: bold;
}

table tbody tr:nth-of-type(even) {
    background-color: #f5f5f6;
}

table tbody tr:hover {
    font-weight: bold;
    color: #0b71a1;
}
`

export default Content
