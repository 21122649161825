import styled from 'styled-components/macro'
import { mediaQueries } from '@commonground/design-system'

export const StyledSideNav = styled.div`
  width: 100%;
  ${mediaQueries.smUp`
    width: 25%;
  `}

  padding-top: ${(p) => p.theme.tokens.spacing08};
  padding-bottom: ${(p) => p.theme.tokens.spacing08};

  ${mediaQueries.smUp`
    padding-left: ${(p) => p.theme.tokens.spacing04};
    padding-right: ${(p) => p.theme.tokens.spacing06};
  `}

  ul {
    padding: 0;
    list-style: none;

    margin-top: ${(p) => p.theme.tokens.spacing02};
    margin-bottom: ${(p) => p.theme.tokens.spacing06};
  }

  li {
    margin-bottom: ${(p) => p.theme.tokens.spacing01};
  }

  a {
    color: #000;
    text-decoration: none;
  }

  a.active {
    color: #0b71a1;
  }

  hr {
    margin: 0;
  }

  ${mediaQueries.smUp`
    hr {
      display: none;
    }
  `}
`
