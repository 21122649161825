import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Flex from '../components/Flex'
import Container from '../components/Container'
import SideNav from '../components/SideNav'
import Content from '../components/Content'

export default function Template({ data }) {
  const { markdownRemark: post } = data
  console.log(post)
  return (
    <Layout>
      <Seo title={post.frontmatter.title} />
      <Container>
        <Flex>
          <SideNav title={post.frontmatter.path}/>
          <Content>
            <h1>{post.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </Content>
        </Flex>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        path
        title
      }
      html
    }
  }
`
